import {
	ACCOUNT_DELIVERY_ADDRESSES_PATH,
	ACCOUNT_MY_ORDERS_PATH,
	ACCOUNT_MY_SUBSCRIPTIONS,
	ACCOUNT_PATH,
	ACCOUNT_PERSONAL_DETAILS_PATH,
	BROWSE_DEPARTMENTS_PATH,
	BROWSE_PATH,
	DELIVERYSUBSCRIPTION_PLANS_PATH,
	FAVOURITES_PATH,
	HOME_PATH,
	LIQUOR_LICENSES_PATH,
	LISTS_PATH,
	MODAL_ROUTES,
	PRIVACY_POLICY_PATH,
	RECIPES_PATH,
	SHOPPING_LISTS_PATH,
	SPECIALS_PATH,
	STORE_LOCATOR_PATH,
	TERM_CONDITIONS_PATH,
} from '@woolworthsnz/shop';
import { AlertType, EmbeddedNavigationItem, ReducedRangeAlertType, THEME_CONSTANTS } from '@woolworthsnz/styleguide';
import { NavigationItem, NavigationItemGroup } from '@woolworthsnz/trader-api';
import { environment } from '../environments/environment';

export interface Meta {
	title: string;
	embeddedTitle: string;
	description?: string;
	url?: string;
	image?: string;
}

export const actions = [
	{
		url: '/shop/accountdetails',
		text: 'My Account',
	},
	{
		url: '/shop/help',
		text: 'Help',
	},
	{
		url: '/about-us/contact-us',
		text: 'Contact',
	},
];

export const quickNav = [
	{
		url: '/about-us/contact-us',
		text: 'Contact',
	},
	{
		url: `/${STORE_LOCATOR_PATH}`,
		text: 'Stores',
	},
];

export const main = [
	{
		url: HOME_PATH,
		text: 'Home',
	},
	{
		url: BROWSE_PATH,
		text: 'Browse',
		subnav: true,
	},
	{
		url: SPECIALS_PATH,
		text: 'Specials',
		subnav: false,
	},
	{
		url: RECIPES_PATH,
		text: 'Recipes',
		subnav: false,
	},
	{
		url: `${LISTS_PATH}/${FAVOURITES_PATH}`,
		text: 'My Favourites',
	},
];

export const embedded: EmbeddedNavigationItem[] = [
	{
		url: HOME_PATH,
		text: 'Shop',
		exact: true,
	},
	{
		url: BROWSE_DEPARTMENTS_PATH,
		text: 'Browse',
	},
	{
		url: SPECIALS_PATH,
		text: 'Specials',
	},
	{
		url: `${LISTS_PATH}/${FAVOURITES_PATH}`,
		text: 'My Favourites',
	},
];

// This gets appended with the main nav and other dynamic stuff
export const mobileNav = [
	{
		url: `/${SHOPPING_LISTS_PATH}`,
		text: 'Shopping List',
	},
	{
		url: '/shop/accountdetails',
		text: 'My Account',
	},
	{
		url: `/${STORE_LOCATOR_PATH}`,
		text: 'Store Finder',
	},
	{
		url: '/shop/faqs',
		text: 'Help',
	},
	{
		url: '/shop/content/mycountdownapp',
		text: 'Woolworths NZ App',
	},
	{
		url: '/about-us/contact-us',
		text: 'Contact',
	},
];

export const myAccountNav = [
	{
		label: 'Online shopping',
		icon: 'trolley',
		items: [
			{
				url: `${ACCOUNT_PATH}/${ACCOUNT_MY_ORDERS_PATH}`,
				label: 'My orders',
				shopper: true,
			},
			{
				url: `${ACCOUNT_PATH}/${ACCOUNT_MY_SUBSCRIPTIONS}`,
				label: 'Delivery Saver',
				shopper: true,
			},
			{
				url: `${ACCOUNT_PATH}/${ACCOUNT_DELIVERY_ADDRESSES_PATH}`,
				label: 'Delivery addresses',
				shopper: true,
			},
			{
				url: `${ACCOUNT_PATH}/${ACCOUNT_PERSONAL_DETAILS_PATH}`,
				label: 'Personal details',
				shopper: true,
			},
			// not shopper
			{
				url: '/shop/register',
				label: 'Register for online shopping',
				shopper: false,
			},
		],
	},
];

export const onesiteFooterNav: NavigationItemGroup[] = [
	{
		label: 'Save at Countdown',
		items: [
			{
				id: 0,
				label: 'Specials & offers',
				url: `/${SPECIALS_PATH}`,
			},
			{
				id: 1,
				label: 'Mailer',
				url: `/shop/content/weeklymailer`,
			},
			{
				id: 2,
				label: 'Delivery saver',
				url: `/${DELIVERYSUBSCRIPTION_PLANS_PATH}`,
			},
			{
				id: 3,
				label: 'Boosts',
			},
			{
				id: 4,
				label: 'More ways to save',
				url: '/info/save',
			},
		],
	},
	{
		label: 'Stores and services',
		items: [
			{
				id: 0,
				label: 'Store locations and hours',
				url: `/${STORE_LOCATOR_PATH}`,
			},
			{
				id: 1,
				label: 'Online shopping',
				url: '/info/online-shopping',
			},
			{
				id: 2,
				label: 'Pharmacy',
				url: '/info/services/pharmacy',
			},
			{
				id: 3,
				label: 'Gift cards',
				url: '/info/services/gift-cards',
			},
			{
				id: 4,
				label: 'More services',
				url: '/info/services',
			},
		],
	},
	{
		label: 'Customer service',
		items: [
			{
				id: 0,
				url: `${ACCOUNT_PATH}/${ACCOUNT_MY_ORDERS_PATH}`,
				label: 'My orders',
			},
			{
				id: 1,
				label: 'Manage account',
				url: `/${ACCOUNT_PATH}`,
			},
			{
				id: 2,
				label: 'FAQs',
				url: '/shop/content/faqs',
			},
			{
				id: 3,
				label: 'Contact us',
				url: '/about-us/contact-us',
			},
		],
	},
	{
		label: 'About Woolworths NZ',
		items: [
			{
				id: 0,
				label: 'About us',
				url: '/info/about-us',
			},
			{
				id: 1,
				label: 'Community and environment',
				url: '/info/community-and-environment',
			},
			{
				id: 2,
				label: 'Careers',
				url: '/info/careers',
			},
			{
				id: 3,
				label: 'News and media',
				url: '/info/news-and-media-releases',
			},
			{
				id: 4,
				label: 'Wholesale supply',
				url: '/info/about-us/about-woolworths-nz',
			},
		],
	},
];

export const legalBarNav: NavigationItem[] = [
	{
		id: 0,
		label: 'Liquor licenses',
		url: `/${LIQUOR_LICENSES_PATH}`,
	},
	{
		id: 1,
		label: 'Pay remediation',
		url: `/shop/content/holidays-act-remediation`,
	},
	{
		id: 2,
		label: 'Privacy centre',
		url: `/${PRIVACY_POLICY_PATH}`,
	},
	{
		id: 3,
		label: 'Terms & conditions',
		url: `/${TERM_CONDITIONS_PATH}`,
	},
];

/* eslint-disable @typescript-eslint/naming-convention */
export class AppSettings {
	static appName = 'pluto';
	static BASEURL = '/api/v1/';
	static BASEURL_V2 = '/api/v2/';
	static BASEURL_WITHOUT_VERSION = '/api/v';
	static BASE_PAGE_URL = 'shop';
	static ITEMS_PER_PAGE = ['24', '48', '120'];
	static MINIMUM_PAGE_SIZE = 24;
	static SEARCH_SORT_OPTIONS = [
		{ text: 'Alphabetical', value: 'name' },
		{ text: 'Price Low to High', value: 'PriceAsc' },
		{ text: 'Price High to Low', value: 'PriceDesc' },
		{ text: 'Unit Price Low to High', value: 'CUPAsc' },
		{ text: 'Unit Price High to Low', value: 'CUPDesc' },
		{ text: 'Recency', value: 'Recency' },
		{ text: 'Relevance', value: 'Relevance' },
		{ text: 'Specials', value: 'Special' },
		{ text: 'Newest', value: 'AvailableDate' },
		{ text: 'In Trolley', value: 'InTrolley' },
		{ text: 'Aisle', value: 'Aisle' },
	];

	/* eslint-enable @typescript-eslint/naming-convention */

	static breakpoints = THEME_CONSTANTS.breakpoints;
	static constants = THEME_CONSTANTS;
	static navs = {
		actions,
		main,
		mobileNav,
		embedded,
		quickNav,
		myAccountNav,
		onesiteFooterNav,
		legalBarNav,
	};

	static modalOutletName = MODAL_ROUTES.MODAL_OUTLET;

	// COVID-19 edge case. Hide specials
	static showSpecials = true;

	// Endpoints needs to be alphabetical by sections
	static endpoints = {
		// Misc
		address: 'addresses',
		logout: 'shopper-logouts',
		productDetails: 'products',
		search: 'products',
		session: 'session',
		shell: 'shell',
		suburbs: 'suburbs',
		trolley: 'trolleys',
		shoppingLists: 'shopping-lists',
		// Addresses
		pickupAddresses: 'addresses/pickup-addresses',
		externalAddress: 'addresses/external-address',

		// Delivery Subscriptions
		deliverySubscriptions: `delivery-subscription`,
		deliverySubscriptionGetPlans: `delivery-subscription/plans`,
		deliverySubscriptionCreateSubscription: `delivery-subscription/subscription`,
		deliverySubscriptionMySubscription: `delivery-subscription/my`,
		deliverySubscriptionAddressEligibility: 'delivery-subscription/my/address-eligibility',

		// Fulfilment
		deleteTimeslots: 'fulfilment/my/time-slots',
		getTimeslots: 'fulfilment/time-slots',
		getTimeslotsSummary: 'fulfilment/time-slots-summary',
		postMethod: `fulfilment/my/methods`,
		postTimeslot: `fulfilment/my/time-slots`,
		postAddress: `fulfilment/my/addresses`,
		postPrimaryAddress: `fulfilment/my/primary-addresses`,
		postPickupAddress: `fulfilment/my/pickup-addresses`,
		postSuburb: `fulfilment/my/suburbs`,
		getExpressTimeslots: `fulfilment/express/time-slots`,
		expressPickupAreas: 'fulfilment/express/pickup-areas',
		expressPickupAvailability: 'fulfilment/express/pickup-availability',
		expressDeliveryAvailability: 'fulfilment/express/delivery-availability',
		reserveExpressPickup: 'fulfilment/my/express/reserve-pickup-slot',
		reserveExpressWindow: 'fulfilment/my/express/reserve-slot',
		fulfilmentStores: 'fulfilment/stores',
		// Products
		productSearchSuggestions: 'products/search-suggestions',
		departments: 'products/departments',
		productDetailSavedList: 'products/my/saved-lists',
		productForgotten: 'products/my/forgotten',
		productStockLevels: 'products/stock-levels',
		// Recipes
		recipes: 'recipes',
		recipeSummary: 'recipes/summary',
		recipeSearchSuggestions: 'recipes/search-suggestions',
		// Shoppers
		onecards: 'shoppers/my/onecards',
		experiments: 'shoppers/my/preferences/experiments',
		fulfilmentNotes: `shoppers/my/instructions/fulfilment`,
		myFavourites: 'shoppers/my/favourites',
		orderNotes: `shoppers/my/instructions/order`,
		packagingOptions: `shoppers/my/packaging`,
		pastOrders: 'shoppers/my/past-orders',
		postCoupon: 'shoppers/my/coupons',
		savedList: 'shoppers/my/saved-lists',
		savedRecipes: 'shoppers/my/recipes',
		shopperNotes: 'shoppers/my/preferences',
		updatePhoneNumbers: `shoppers/my/phone-numbers`,
		myAccountDetails: 'shoppers/my/details',
		myAccountCredentials: 'shoppers/my/credentials',
		mySettings: 'shoppers/my/settings',
		// Orders
		getOrder: `orders/my/active`,
		getOrderTotals: `orders/my/active/totals`,
		putOrderDisclaimer: `orders/my/active/accept-disclaimer`,
		bonusProducts: `orders/my/active/bonus-products`,
		getOrderValidation: `orders/my/active/validation`,
		getLastOrderChangeContext: 'orders/my/past/last-order-context',
		getOrderChangeContext: 'orders/my/past/order-change-context',
		pastOrderUpdate: 'orders/my/past',
		cancelPastOrderChange: 'orders/my/past/cancel-change',
		intiateOrderChange: 'orders/my/past/initiate-change',
		orderConfirmation: 'order/my/orderconfirmation',
		isOrderCancelleable: 'orders/my/past/is-order-cancellable',
		cancelOrder: 'orders/my/past/cancel-order',
		placeOrder: 'orders/my/active/place',
		// Trolley
		addOrUpdateTrolley: `trolleys/my/items`,
		// Payments
		cardCaptureUrl: 'payments/card-capture-iframe-url',
		paymentInstruments: 'payments/instruments',
		wPayPaymentInstruments: 'payments/instruments/wpay',
		newWpayCheckoutSession: 'payments/instruments/checkoutsession',
		// Dynamic content
		dynamicContent: 'dynamic-content',
		dynamicContentProductGroups: 'dynamic-content/product-groups',
		dynamicContentFormSubmit: 'dynamic-content/forms',
		// Site management
		metaTagSettings: 'settings/meta-tags',
		// Addresses
		addresses: 'addresses',
		locations: 'locations',
		// Guests
		guestOnecards: 'guests/my/onecards',
		// auth
		oidcSignInEndpoint: 'bff/initiate-oidc-signin',
		oidcSignOutEndpoint: 'bff/logout',
		// get-user
		getUserEndpoint: 'bff/get-user',
		// Meal Planning
		mealPlanPreferences: 'mealplans/my/preferences',
		mealPlanTypes: 'mealplans/types',
		mealPlanCuratedRecipes: 'mealplans/curated/recipes',
		mealPlanShortlist: 'mealplans/my/shortlist',
		mealPlanPastMealPlans: 'mealplans',
		// online-samples
		onlineSamples: 'online-samples',
		onlineSampleResponses: 'online-sample-responses',
		onlineSampleProductsForRemarketing: 'online-samples-remarketing',
		// product shelves
		productShelves: 'products/shelves',
		// Loyalty - Everyday Rewards
		loyalty: 'loyalty',
		myOffers: 'loyalty/edr/my/offers',
		// Homepage banners
		homepageBanners: 'banners/homepage',
	};

	static pageUrl = {
		login: `/shop/securelogin`,
		productDetail: `${AppSettings.BASE_PAGE_URL}/productdetails`,
		productSearch: `${AppSettings.BASE_PAGE_URL}/searchproducts`,
		recipeSearch: `${AppSettings.BASE_PAGE_URL}/searchrecipes`,
		browse: `${AppSettings.BASE_PAGE_URL}/browse`,
		specials: `${AppSettings.BASE_PAGE_URL}/specials`,
		deliverySaver: `deliverysubscription/plans`,
		weeklyMailer: `${AppSettings.BASE_PAGE_URL}/content/weeklymailer`,
	};

	static meta: { [key: string]: Meta } = {
		default: {
			title: `Woolworths NZ - Online Grocery Shopping - Formerly Countdown`,
			embeddedTitle: `Shop online`,
			description: `Shop for groceries online and get fresh, free recipes at woolworths.co.nz, formerly countdown.co.nz.`,
			url: 'https://www.woolworths.co.nz',
			image: 'https://www.woolworths.co.nz/Images/Shared/facebook-like-icon.jpg',
		},

		productCategory: {
			title: `Buy {name} online at Woolworths NZ`,
			embeddedTitle: `{name}`,
			description: `{name} - available at Woolworths NZ, formerly Countdown. Order 24/7 at our online supermarket`,
		},
		productDetail: {
			title: `Buy {name} online at Woolworths NZ`,
			embeddedTitle: ` `,
			description: `{name} - available at Woolworths NZ, formerly Countdown. Order 24/7 at our online supermarket`,
		},
		browse: {
			title: `Browse for products online at Woolworths NZ`,
			embeddedTitle: `Browse`,
			description: `Browse for products online at Woolworths NZ, formerly Countdown. Order 24/7 at our online supermarket`,
		},
		specials: {
			title: `Browse special offers online at Woolworths NZ`,
			embeddedTitle: `Specials`,
			description: `Browse special offers online at Woolworths NZ, formerly Countdown. Order 24/7 at our online supermarket`,
		},
		search: {
			title: `{name} - Woolworths NZ`,
			embeddedTitle: `{name}`,
			description: `Shop online at Woolworths NZ, formerly Countdown. Order 24/7 at our online supermarket`,
		},
		myFavourites: {
			title: `My Favourites`,
			embeddedTitle: `My Favourites`,
		},
		savedLists: {
			title: `My Saved Lists`,
			embeddedTitle: `My Saved Lists`,
		},
		register: {
			title: `Register  - Woolworths NZ`,
			embeddedTitle: `Register for online shopping`,
		},
		bookATimeslot: {
			title: 'Schedule your order - Woolworths NZ',
			embeddedTitle: 'Schedule your order',
		},
		viewTrolley: {
			title: 'Trolley',
			embeddedTitle: 'Trolley',
		},
		haveYouForgotten: {
			title: 'Have you forgotten?',
			embeddedTitle: 'Have you forgotten?',
		},
		reviewOrder: {
			title: 'Review Order',
			embeddedTitle: 'Review Order',
		},
		payment: {
			title: 'Payment',
			embeddedTitle: 'Payment',
		},
		confirmation: {
			title: 'Order Confirmation',
			embeddedTitle: 'Order Confirmation',
		},
		pastOrders: {
			title: 'Past Orders',
			embeddedTitle: 'Past Orders',
		},
		savedRecipes: {
			title: 'Saved Recipes',
			embeddedTitle: 'Saved Recipes',
		},
		deliverySubscription: {
			title: 'Delivery Subscription',
			embeddedTitle: 'Delivery Subscription',
		},
		mealPlanningLanding: {
			title: 'Meal Planning',
			embeddedTitle: 'Meal Planning',
			description: 'Woolworths NZ | Plan your meals with Woolworths NZ meal planning.',
		},
		mealPlanningChooseMeals: {
			title: 'Choose Meals',
			embeddedTitle: 'Choose Meals',
			description: 'Woolworths NZ | Choose meals for your meal plan',
		},
		mealPlanningMyMealPlans: {
			title: 'My Meal Plans',
			embeddedTitle: 'My Meal Plans',
			description: 'Woolworths NZ | View your meal plans',
		},
		mealPlanningReviewPlan: {
			title: 'Review Plan',
			embeddedTitle: 'Review Plan',
			description: 'Woolworths NZ | Review your meal plan',
		},
		recipeDetail: {
			title: 'Easy {name} recipe | Woolworths NZ',
			embeddedTitle: ' {name} recipe',
			description:
				'Woolworths NZ | Try our easy {name} recipe today - try something a bit different with our easy to follow recipes.',
		},
		recipeLanding: {
			title: 'Easy recipes for you and your family | Woolworths NZ',
			embeddedTitle: 'Recipes',
			description:
				'Woolworths NZ | Take a look at our simple recipes for you and your family. Let us help to inspire you in the kitchen!',
		},
		recipeCategory: {
			title: 'Easy {name} ideas and recipes | Woolworths NZ',
			embeddedTitle: '{name}',
			description:
				'Woolworths NZ | Take a look at our simple {name} recipes for you and your family. Let us help to inspire you in the kitchen!',
		},
		recipeSearch: {
			title: 'Search results for {name} | Woolworths NZ',
			embeddedTitle: 'Recipe results',
			description: 'Woolworths NZ | Search results for {name} - try these recipes today.',
		},
		storeLocator: {
			title: 'Store Finder',
			embeddedTitle: 'Store Finder',
			image: 'https://www.woolworths.co.nz/media/9934/countdown_2018_pos_h_rgb_site.png',
		},
		contactUs: {
			title: 'Contact Us',
			embeddedTitle: 'Contact Us',
		},
		account: {
			title: 'Account',
			embeddedTitle: 'Account',
		},
		accountPersonalDetails: {
			title: 'Personal details',
			embeddedTitle: 'Personal details',
		},
		accountDeliveryAddresses: {
			title: 'Delivery Addresses',
			embeddedTitle: 'Delivery Addresses',
		},
		accountEmailPassword: {
			title: 'Manage email and password',
			embeddedTitle: 'Manage email and password',
		},
		accountOnlineShoppingPreferences: {
			title: 'Online shopping preferences',
			embeddedTitle: 'Online shopping preferences',
		},
		accountOnecardForOLS: {
			title: 'Onecard for online shopping',
			embeddedTitle: 'Onecard for online shopping',
		},
		accountMyOrders: {
			title: 'My orders',
			embeddedTitle: 'My orders',
		},
		accountMySubscriptions: {
			title: 'My Subscriptions',
			embeddedTitle: 'My Subscriptions',
		},
	};

	// TODO: POD-6186: Grab these from Trader
	static deliveryAlertMessages = {
		[ReducedRangeAlertType.OutOfZone]: {
			title: `We're unable to deliver to your saved address`,
			description: `You can still place an order for 'Pick up' above, or you can update your delivery address.`,
			alertType: AlertType.warning,
		},
		[ReducedRangeAlertType.NonPerishable]: {
			title: `Your address is in a 'Dry Goods' area`,
			description: `You'll notice the range of goods available to you for delivery are dry goods only. If you wish to view our full range, try a different address`,
			alertType: AlertType.info,
		},
		[ReducedRangeAlertType.LimitedPerishable]: {
			title: `The address you have entered is in a Limited Perishables area for delivery`,
			description: `You'll notice a limited range of products available for you to select for delivery. If you wish to view and select more products, you are welcome to select the ‘Pick-Up’ method.`,
			alertType: AlertType.info,
		},
	};

	static storeSearchEndpoint = '/api/v1/sites';
	static storeSearchEndpointV2 = '/api/v2/sites';

	static styleGuideConfig = {
		minimumAge: 13,
		password: {
			minLength: 8,
			requiredCharGroups: 2,
		},

		// TODO: CO-7274 needs to remove this features object related file
		...THEME_CONSTANTS,
		environment: environment,
	};

	static scriptLocations = {
		dynamicContentEditor: 'assets/dynamic-content-editor/dynamiccontent2.bundle.js',
	};

	static ssuConfigs = {
		reAuthPath: '/api/v1/registrations/password',
	};
}
